import React, { memo } from "react";
import SvgIcon from "@mui/material/SvgIcon";
import { makeStyles } from "@mui/styles";

export const ArrowIcon = memo(({ flip }) => {
  const useStyles = makeStyles({
    icon: {
      width: "15px",
      height: "15px",
      justifySelf: "center",
      alignSelf: "center",
      marginBottom: "-3px",
      marginLeft: "1px",
      transform: flip ? "rotate(180deg)" : "rotate(0deg)",
    },
  });

  const styles = useStyles();

  return (
    <SvgIcon viewBox="0 0 330 330" className={styles.icon}>
      <path
        d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394 l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393 C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z"
        fill="red"
        fillOpacity="0.87"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
});
