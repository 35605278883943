import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "@emotion/styled";

import PageContainer from "../../components/grid/PageContainer.jsx";
import { VolumeSimulatorChat } from "./Chat/index.js";
import { UserSelectors, UISelectors, UIActions } from "../../store/index.js";
import VolumeSimulatorComponents from "../../products/volume-sim/index.js";
import { Footer } from "../../components/Footer/index.jsx";
import { useChatActions } from "../hooks/useChatActions.js";
import {
  userAccessDepotListSelector,
  showErrorModalSelector,
} from "../../products/volume-sim/reducers/app/selector.js";
import { AppActions } from "../../store/index.js";
import { getUserData } from "../../products/volume-sim/utils/api.js";
import {
  setUserAccessDepotList,
  resetAppState,
  setShowErrorModal,
  setErrorModalMessage,
} from "../../products/volume-sim/reducers/app/index.js";
import { trimString, emailToSpannerId } from "../../utils/string.js";
import {
  handleSimulation,
  handleVolumeSimulatorDepotChange,
  handleVolumeSimulatorDateChange,
} from "../../products/volume-sim/reducers/app/thunkActions.js";
import MessageForm from "../../components/Chat/MessageForm.jsx";
import {
  FULL_CHAT_INDEX,
  PAGE_SPLIT_5050_INDEX,
  RATIO_BUTTON_RATIOS,
} from "../../constants/footer.js";

const { getActiveScreenRatioButtonIndex } = UISelectors;
const { setActiveScreenRatioButtonIndex } = UIActions;

const ContentContainer = styled("div")(({ screenRatio }) => ({
  display: "grid",
  // justifyContent: "flex-end",
  gridTemplateColumns: screenRatio,
  height: "92%",
  // rowGap: theme.spaces.xxLarge,
}));

export const VolumeSimulatorPage = () => {
  const dispatch = useDispatch();
  const { resetChat, onSendMessage, isTyping, activeTopicId } =
    useChatActions();
  const userDepotList = useSelector(userAccessDepotListSelector);
  const showErrorModal = useSelector(showErrorModalSelector);
  const activeScreenRatioButtonIndex = useSelector(
    getActiveScreenRatioButtonIndex
  );
  const { setLoading } = AppActions;

  const spannerUserId =
    emailToSpannerId(useSelector(UserSelectors.getUser)?.email) || "";

  useEffect(() => {
    dispatch(resetAppState());
    const findUser = async () => {
      try {
        dispatch(setLoading(true));
        const user = await getUserData(spannerUserId);

        dispatch(setUserAccessDepotList(user.data.data.user.depotAccess));
        dispatch(setLoading(false));
      } catch (e) {
        dispatch(setShowErrorModal(true));
        dispatch(setErrorModalMessage("Getting User Depot Data"));
        dispatch(setLoading(false));
      }
    };

    findUser();
  }, [dispatch, setLoading, spannerUserId]);

  const sendMessageToVertex = async (message) => {
    const filteredDepots = await userDepotList.map(
      ({ depotCode, depotName }) => ({
        depotCode,
        depotName,
      })
    );
    const res = await onSendMessage(trimString(message), filteredDepots);
    const {
      depotCodeList = [],
      depotNameList = [],
      date = null,
      percentage = 0,
      districtAndSectorList = [],
    } = await res.messages[0];
    // Stops requests going through if there's no depot code -> Exception for simulating certain sectors and districts without mentioning depotCode
    if (
      (!depotCodeList && !districtAndSectorList) ||
      (depotCodeList?.length < 1 && districtAndSectorList?.length < 1)
    )
      return;

    dispatch(setLoading(true));
    try {
      await dispatch(
        handleVolumeSimulatorDepotChange({
          depotCodeList,
          depotNameList,
          districtAndSectorList,
        })
      );
      if (date) {
        await dispatch(
          handleVolumeSimulatorDateChange({
            date,
            depotCodeList,
            districtAndSectorList,
          })
        );
      }
      if (percentage) {
        await dispatch(
          handleSimulation({
            percentage: percentage / 100,
            depotCodeList,
            date,
            districtAndSectorList,
          })
        );
      }
      // If they make a request while the chat is full screen then they'd see nothing - So swap to a view with volume sim visible
      if (activeScreenRatioButtonIndex === FULL_CHAT_INDEX) {
        dispatch(setActiveScreenRatioButtonIndex(PAGE_SPLIT_5050_INDEX));
      }
      dispatch(setLoading(false));
    } catch (e) {
      // Each function already has its own error handling - this is here to stop them running if one of the functions before fails
      dispatch(setLoading(false));
    }
  };

  return (
    <PageContainer>
      <ContentContainer
        screenRatio={RATIO_BUTTON_RATIOS[activeScreenRatioButtonIndex]}
      >
        <VolumeSimulatorChat isTyping={isTyping} />
        <VolumeSimulatorComponents
          showErrorModal={showErrorModal}
          activeScreenRatioButtonIndex={activeScreenRatioButtonIndex}
        />
      </ContentContainer>
      <Footer resetChat={resetChat}>
        <MessageForm
          sendMessageToVertex={sendMessageToVertex}
          isTyping={isTyping}
          activeTopicId={activeTopicId}
        />
      </Footer>
    </PageContainer>
  );
};
