import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Slider } from "@mui/material";
import { withStyles } from "@mui/styles";

import { palette } from "../../../styles/palette";
import {
  setSimulationPercentage,
  handleSimulation,
} from "../../../reducers/app";
import {
  defaultOverlayDataSelector,
  simulationPercentageSelector,
  volumeOverlayDateSelector,
  depotCodeListSelector,
} from "../../../reducers/app/selector";
import { SLIDER_VALUES } from "../../../constants/sliderValues";
import "./SimulationSlider.css";

const StyledSlider = withStyles(() => ({
  root: {
    width: "100%",
  },
  thumb: {
    color: palette.dpdRed,
  },
  track: {
    color: palette.dpdRed,
  },
  rail: {
    color: palette.flamingo,
  },
  mark: {
    color: palette.dpdRed,
  },
  markLabel: {
    color: palette.slate,
  },
  markLabelActive: {
    color: palette.slate,
  },
  valueLabel: {
    padding: "0 10px",
  },
}))((props) => <Slider {...props} />);

export const SimulationSlider = ({ setLoading }) => {
  const [errorMsg, setErrorMsg] = useState("");

  const percentageValue = useSelector(simulationPercentageSelector);
  const depotCodeList = useSelector(depotCodeListSelector);
  const volumeOverlayDate = useSelector(volumeOverlayDateSelector);
  const defaultOverlayDataList = useSelector(defaultOverlayDataSelector);

  useEffect(() => {
    if (
      defaultOverlayDataList.length < 1 &&
      depotCodeList.length < 1 &&
      volumeOverlayDate
    ) {
      setErrorMsg(
        `No Data Found for Date: ${volumeOverlayDate} and Depots: ${depotCodeList.toString()}`
      );
    }
  }, [defaultOverlayDataList, depotCodeList, volumeOverlayDate]);

  const dispatch = useDispatch();

  const sliderMarks = SLIDER_VALUES.MARKS;
  let currentValue = sliderMarks[0];
  const sliderLength =
    (sliderMarks[sliderMarks.length - 1] - sliderMarks[0]) / 10;
  const sliderValues = [];

  for (let index = 0; index <= sliderLength; index++) {
    sliderValues.push({
      value: index,
      label: sliderMarks.includes(currentValue) ? `${currentValue}%` : null,
      popup: `${currentValue}%`,
      percentage: currentValue / 100,
    });
    currentValue = currentValue + 10;
  }

  // Always ensures zero is the default value regardless of the array (i.e, has the negative values)
  const getIndex = (percentageToFind) =>
    sliderValues.findIndex((value) => value.percentage === percentageToFind);

  return (
    <>
      {defaultOverlayDataList.length > 0 ? (
        <div
          className="simulationContainer"
          key={`simulationContainer${percentageValue}`}
        >
          <p className="simulationSideBarTitle">Choose value(%) to simulate</p>
          <div className="simulationSliderContainer">
            <StyledSlider
              key={`${volumeOverlayDate}-sidebar-slider`}
              marks={sliderValues}
              max={sliderValues.length - 1}
              step={1}
              defaultValue={
                percentageValue !== 0 ? getIndex(percentageValue) : getIndex(0)
              }
              valueLabelDisplay={"auto"}
              onChangeCommitted={(e, value) => {
                dispatch(
                  setSimulationPercentage(sliderValues[value].percentage)
                );
              }}
              valueLabelFormat={(value) => {
                return sliderValues[value].popup;
              }}
            />
            <button
              className="sideBarButton"
              onClick={async () => {
                dispatch(setLoading(true));
                // Ignore await message - await IS needed here otherwise loading screen will disappear instantly
                await dispatch(
                  handleSimulation({
                    percentage: percentageValue,
                    depotCodeList,
                    date: volumeOverlayDate,
                  })
                );
                dispatch(setLoading(false));
              }}
            >
              {percentageValue === 0
                ? "SHOW ORIGINAL VALUES"
                : `SIMULATE ${Math.abs(percentageValue * 100)}% ${
                    percentageValue > 0 ? "INCREASE" : "DECREASE"
                  }`}
            </button>
          </div>
        </div>
      ) : (
        <div className="simulationErrorMsg">{errorMsg}</div>
      )}
    </>
  );
};
