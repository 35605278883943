import React from "react";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { palette } from "../../theme/theme.enums";
import { UIActions, UISelectors } from "../../store";

const ButtonContainer = styled.div(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  columnGap: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  padding: "0.5rem 0",
}));

const RatioButton = styled(Button)(({ background, index }) => ({
  width: "80%",
  height: "80%",
  cursor: "pointer",
  pointerEvents: "all !important",
  background,
  "&:hover": {
    background,
  },
  "&:disabled": {
    pointerEvents: "unset",
    cursor: "not-allowed",
  },
}));

const { getActiveScreenRatioButtonIndex } = UISelectors;
const { setActiveScreenRatioButtonIndex } = UIActions;

const IconGrid = () => {
  const onHomePage = useLocation().pathname === "/";
  const dispatch = useDispatch();
  const activeScreenRatioButtonIndex = useSelector(
    getActiveScreenRatioButtonIndex
  );

  const { brandRed, gray70, gray10, infographicRed } = palette;

  const fullColour = (buttonIndex, onColour, offColour) =>
    activeScreenRatioButtonIndex === buttonIndex && !onHomePage
      ? onColour
      : offColour;
  const splitColour = (buttonIndex, ratio1, ratio2) =>
    activeScreenRatioButtonIndex === buttonIndex && !onHomePage
      ? `linear-gradient(to right, ${infographicRed} ${ratio1}%, ${brandRed} ${ratio2}%)`
      : `linear-gradient(to right, ${gray10} ${ratio1}%, ${gray70} ${ratio2}%)`;

  const buttonStyles = [
    fullColour(0, infographicRed, gray10), // 100% / 0%
    splitColour(1, 80, 20), // 80% / 20%
    splitColour(2, 50, 50), // 50% / 50%
    fullColour(3, brandRed, gray70), // 0% / 100%
  ];

  return (
    <ButtonContainer>
      {buttonStyles.map((buttonStyle, index) => (
        <RatioButton
          background={buttonStyle}
          disabled={onHomePage}
          key={index}
          onClick={() => dispatch(setActiveScreenRatioButtonIndex(index))}
        />
      ))}
    </ButtonContainer>
  );
};

export default IconGrid;
