import { createSelector } from "@reduxjs/toolkit";
import { SNACKBAR_TRIGGER_COUNT } from "../../../constants/cards";

export const getHomeFailedWidgets = (state) => state.ui.home.homeFailedWidgets;
export const isHomeReloadSnackbarOpen = (state) =>
  state.ui.home.isReloadSnackbarOpen;

export const shouldReloadHomeWidgets = (state) =>
  state.ui.home.shouldReloadAllWidgets;

export const getFailedWidgetsCount = createSelector(
  getHomeFailedWidgets,
  (failedWidgets) => failedWidgets.length
);

export const shouldOpenSnackbar = createSelector(
  getFailedWidgetsCount,
  (count) => count >= SNACKBAR_TRIGGER_COUNT
);

export const getChatWidgets = (state) => state.ui.chat;

export const isHomeOrderChanged = (state) => state.ui.home.isHomeOrderChanged;
export const getIsSorting = (state) => state.ui.home.isSorting;

export const getCategoryWidgets = (state) => state.ui.categoryWidgets.widgets;
export const getCategoryWidgetById = (id) =>
  createSelector(getCategoryWidgets, (widgets) =>
    widgets.find((widget) => widget.id === id)
  );

export const getActiveScreenRatioButtonIndex = (state) =>
  state.ui.footer.activeScreenRatioButtonIndex;
