import React, { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";

import Chat from "../../../components/Chat/Chat";
import { getFormattedMessages } from "../../models/messages";
import {
  MessagesSelectors,
  MessagesActions,
  UserSelectors,
} from "../../../store";
import useModal from "../../hooks/useModal";

const Segment = styled("div")(() => ({
  height: "100%",
  width: "100%",
  overflow: "hidden",
}));

export const VolumeSimulatorChat = ({ isTyping }) => {
  const dispatch = useDispatch();
  const messages = useSelector(MessagesSelectors.getMessages);
  const user = useSelector(UserSelectors.getUser);

  const onSubmitFeedback = useCallback(
    async (messageId, feedbackComment) => {
      const { payload } = dispatch();
      MessagesActions.dislikeMessage({
        messageId,
        feedbackComment,
      });

      if (payload) {
        dispatch(MessagesActions.dislikeLocalMessage(messageId));
      }
    },
    [dispatch]
  );

  const { onModalOpen } = useModal({
    onSubmit: onSubmitFeedback,
  });

  const onThumbUpClick = useCallback(
    async (messageId) => {
      const { payload } = await dispatch();
      MessagesActions.likeMessage(messageId);

      if (payload) {
        dispatch(MessagesActions.likeLocalMessage(messageId));
      }
    },
    [dispatch]
  );

  const preparedMessages = useMemo(
    () => getFormattedMessages(messages),
    [messages]
  );

  return (
    <Segment>
      <Chat
        messages={preparedMessages}
        user={user}
        onThumbDownClick={onModalOpen}
        onThumbUpClick={onThumbUpClick}
        isTyping={isTyping}
      />
    </Segment>
  );
};
