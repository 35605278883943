import { Provider as OraclePackageWrapper } from "@dpdgroupuk/ai-app";
import { Route } from "react-router-dom";
import { api } from "../api/api";
import { ROUTES, PAGE_TITLE_ROUTE_MAP } from "../constants/navigation";
import { HomePage } from "../pages/Home";
import { VolumeSimulatorPage } from "../pages/VolumeSimulator";

const { REACT_APP_MAIN_PROJECT_URL, REACT_APP_MAIN_PROJECT_API } = process.env;

const { HOME, VOLUME_SIM } = ROUTES;

export const SiteRoutes = () => {
  return (
    <OraclePackageWrapper
      axiosInstance={api}
      mainProjectUrl={REACT_APP_MAIN_PROJECT_URL}
      mainProjectApiUrl={REACT_APP_MAIN_PROJECT_API}
      pageTitleMapper={PAGE_TITLE_ROUTE_MAP}
    >
      <Route path={HOME} element={<HomePage />} />
      <Route path={VOLUME_SIM} element={<VolumeSimulatorPage />} />
    </OraclePackageWrapper>
  );
};
