import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { IconButton, Button, InputBase } from "@mui/material";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import styled from "@emotion/styled";

import { trimString } from "../../utils/string";
import { MAX_CHARS_COUNT, MIN_CHARS_COUNT } from "../../constants";
import { FULL_CHAT_INDEX } from "../../constants/footer";
import { UIActions, AppActions } from "../../store";

const ChatBoxContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "55%",
  width: "65%",
  gap: 0,
  alignItems: "center",
}));

const InputContainer = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  flexDirection: "row",
  flexGrow: 1,
  height: "100%",
}));

const InputButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  zIndex: 1,
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  width: "100%",
  height: "100%",
  border: `1px solid ${theme.colors.warmGrey80}`,
  backgroundColor: theme.colors.brandWhite,
  fontSize: 16,
  padding: "0.3rem 0",
  paddingLeft: "2.6rem",
  borderTopLeftRadius: theme.radius.small,
  borderBottomLeftRadius: theme.radius.small,
  "& .MuiInputBase-input": {
    padding: 0,
    height: "auto",
    color: theme.colors.brandBlack,
    "&::placeholder": {
      opacity: 0.5,
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "10%",
  height: "100%",
  color: "#FFF",
  cursor: "pointer",
  fontFamily: theme.fonts.plutoRegular,
  textTransform: "capitalize",
  borderTopRightRadius: theme.radius.small,
  borderBottomRightRadius: theme.radius.small,
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  backgroundColor: theme.colors.brandRed,
  fontSize: "0.9rem",
  "&:disabled": {
    pointerEvents: "unset",
    color: theme.colors.brandWhite,
    backgroundColor: theme.colors.warmGrey80,
    cursor: "not-allowed",
  },
  "&:hover": {
    backgroundColor: theme.colors.brandRed,
  },
}));

const { setActiveScreenRatioButtonIndex } = UIActions;
const { setLoading } = AppActions;

const MessageForm = ({ sendMessageToVertex, isTyping, activeTopicId }) => {
  const [message, setMessage] = useState("");
  const inputRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch();

  const isSendDisabled = useMemo(() => {
    const string = trimString(message);

    return !string || string.length < MIN_CHARS_COUNT || isTyping;
  }, [message, isTyping]);

  useEffect(() => {
    setMessage("");
    setTimeout(() => inputRef.current?.focus(), 100);
  }, [activeTopicId]);

  const onMessageSend = async () => {
    setMessage("");
    await sendMessageToVertex(message);
    dispatch(setLoading(false));
  };

  return (
    <ChatBoxContainer>
      {/* TODO add function to button */}
      {location.pathname === "/" && (
        <IconButton>
          <AttachFileIcon />
        </IconButton>
      )}
      <InputContainer>
        <InputButton
          onClick={() =>
            dispatch(setActiveScreenRatioButtonIndex(FULL_CHAT_INDEX))
          }
        >
          <FullscreenIcon />
        </InputButton>
        <StyledInput
          inputRef={inputRef}
          inputProps={{ maxLength: MAX_CHARS_COUNT }}
          value={message}
          autoFocus
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={(e) =>
            e.key === "Enter" && !isSendDisabled && onMessageSend()
          }
          placeholder={"Ask DPD Oracle"}
        />
        <StyledButton onClick={() => onMessageSend()} disabled={isSendDisabled}>
          SUBMIT
        </StyledButton>
      </InputContainer>
    </ChatBoxContainer>
  );
};

export default MessageForm;
