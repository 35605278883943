import { useCallback, useState } from "react";

const useModal = ({ onSubmit, onCancel }) => {
  const [open, setOpen] = useState(false);
  const [modalParams, setParams] = useState();

  const onModalClose = useCallback(() => {
    setOpen(false);
    setParams(null);
  }, []);

  const onModalOpen = useCallback((params) => {
    setOpen(true);

    setParams(params);
  }, []);

  const onSubmitClick = useCallback(
    (params) => {
      onSubmit && onSubmit(modalParams, params);

      onModalClose();
    },
    [onSubmit, modalParams, onModalClose]
  );

  const onCancelClick = useCallback(() => {
    onCancel && onCancel(modalParams);

    onModalClose();
  }, [onCancel, modalParams, onModalClose]);

  return {
    open,
    onModalOpen,
    onSubmitClick,
    onCancelClick,
  };
};

export default useModal;
