import styled from "@emotion/styled";

import MessageList from "./MessageList.jsx";

const ChatPageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  height: "100%",
  gap: "1rem",
  flex: 1,
  flexDirection: "row",
  wordBreak: "break-word",
  alignItems: "center",
  paddingTop: "0.1rem",
  [theme.breakpoints.down("md")]: {
    width: "200vw",
    paddingBottom: `${theme.dimensions.chat.switchButtonsHeight}px`,
    transition: "0.5s ease",
    transform: "translateX(0)",
    margin: `0 ${theme.spaces.xxLarge}px`,
  },
}));

// TODO - Chat could become a reusable component, only MessageForm will differ based on the product
const Chat = ({
  messages,
  user,
  onThumbDownClick,
  onThumbUpClick,
  isTyping,
}) => (
  <ChatPageContainer>
    <MessageList
      user={user}
      messages={messages}
      onThumbDownClick={onThumbDownClick}
      onThumbUpClick={onThumbUpClick}
      isTyping={isTyping}
    />
  </ChatPageContainer>
);

export default Chat;
