import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { WidgetsActions } from "../widgets";
import { CARD_TYPES } from "../../../constants/cards";
import { EMPTY_TOPIC_ID } from "../../../constants";

const initialState = {
  home: {
    homeFailedWidgets: [],
    shouldReloadAllWidgets: false,
    isReloadSnackbarOpen: false,
    initialHome: [],
    isHomeOrderChanged: false,
    isSorting: false,
  },
  chat: [],
  categoryWidgets: {
    widgets: [],
  },
  footer: {
    activeScreenRatioButtonIndex: 2, // Default to 50/50 button
  },
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setChatWidget: (state, action) => {
      const cardData = action.payload.cardData;

      if (!cardData || (cardData && !CARD_TYPES[cardData?.type])) {
        state.chat = [];
        return;
      }

      const question = action.payload.messages[0].question;
      const topicId = action.payload.attributes.topicId;
      const messageId = action.payload.messages[0].id;

      state.chat = [{ ...cardData, question, topicId, messageId }];
    },
    resetChatWidgets: (state) => {
      state.chat = initialState.chat;
    },
    addChatWidget: (state, action) => {
      state.chat = [action.payload];
    },
    reloadAllHomeWidgets: (state, action) => {
      state.home.shouldReloadAllWidgets = action.payload;
    },
    showHomeReloadSnackbar: (state, action) => {
      state.home.isReloadSnackbarOpen = action.payload;
    },
    resetHomeUi: (state) => {
      state.home = initialState.home;
    },
    resetCategoryWidgets: (state) => {
      state.categoryWidgets.widgets = initialState.categoryWidgets.widgets;
    },
    saveSortingOrder: (state, action) => {
      state.home.isSorting = action.payload.isSorting;
      if (action.payload.isSorting) {
        state.home.initialHome = action.payload.widgets;
        state.home.isHomeOrderChanged = false;
      }
    },
    setActiveScreenRatioButtonIndex: (state, action) => {
      state.footer.activeScreenRatioButtonIndex = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(WidgetsActions.getWidget.fulfilled, (state, action) => {
      const id = action.meta.arg;
      const { isSystem } = action.payload;

      if (isSystem) {
        state.home.homeFailedWidgets.push(id);
      }
    });
    builder.addCase(WidgetsActions.getWidget.rejected, (state, action) => {
      const id = action.meta.arg;

      if (!action.meta.aborted) {
        state.home.homeFailedWidgets.push(id);
      }
    });
    builder.addCase(WidgetsActions.setWidgets, (state, action) => {
      state.home.isHomeOrderChanged = state.home.initialHome.some(
        (id, i) => id !== action.payload[i].id
      );
    });
    builder.addCase(
      WidgetsActions.getCategoryWidgets.fulfilled,
      (state, action) => {
        state.categoryWidgets.widgets = action.payload.map((widget) => ({
          ...widget,
          topicId: EMPTY_TOPIC_ID, // todo temp solution
        }));
      }
    );
    builder.addMatcher(
      isAnyOf(
        WidgetsActions.getWidget.pending,
        WidgetsActions.onRemoveHomeWidget.fulfilled
      ),
      (state, action) => {
        const id = action.meta.arg;

        state.home.homeFailedWidgets = state.home.homeFailedWidgets.filter(
          (failedWidgetId) => failedWidgetId !== id
        );
      }
    );
  },
});

export const actions = uiSlice.actions;

export default uiSlice;
