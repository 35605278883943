import React, { memo, useState, useEffect } from "react";
import styled from "@emotion/styled";

import { Header } from "./components/Header";
import Mapper from "./components/Mapper";
import { ErrorModal } from "./components/ErrorModal/index.js";

import { PAGE_SPLIT_8020_INDEX } from "../../constants/footer.js";

const Segment = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  overflow: "hidden",
  "& *": {
    fontFamily: theme.fonts.plutoRegular,
  },
}));

const VolumeSimulatorComponents = ({
  showErrorModal,
  activeScreenRatioButtonIndex,
}) => {
  const [map, setMap] = useState();

  const onMapLoad = (map) => setMap(map);

  useEffect(() => {
    if (map) map.resize();
  }, [activeScreenRatioButtonIndex, map]);

  return (
    <>
      {showErrorModal && <ErrorModal />}
      <Segment>
        {activeScreenRatioButtonIndex !== PAGE_SPLIT_8020_INDEX && (
          <Header map={map} />
        )}
        <Mapper map={map} onMapLoad={onMapLoad} />
      </Segment>
    </>
  );
};

export default memo(VolumeSimulatorComponents);
