export const ROUTES = {
  HOME: "/",
  VOLUME_SIM: "/volumeSimulation",
};

export const PAGE_TITLES = {
  HOME: "Operations",
  VOLUME_SIM: "Operations - Volume Simulation",
};

export const SUB_NAV_ROUTES = {
  NEWS: "news",
  DOCS: "docs",
  ANALYTICS: "analytics",
  CUSTOMER_SERVICES: "customer-services",
};

export const WIDGET_CATEGORIES = {
  PT_POLICY: "pt-policy",
  IT_POLICY: "it-policy",
  CRM: "crm",
};

export const PAGE_TITLE_ROUTE_MAP = [
  {
    routeName: ROUTES.HOME,
    pageTitle: PAGE_TITLES.HOME,
  },
  {
    routeName: ROUTES.VOLUME_SIM,
    pageTitle: PAGE_TITLES.VOLUME_SIM,
    productTitle: "Volume Simulation",
  },
];

export const RIGHT_NAVIGATION_ITEMS = [
  // {
  //   text: PAGE_TITLES.SUB_NAV_NEWS,
  //   icon: null,
  //   routeName: SUB_NAV_ROUTES.NEWS,
  // },
  {
    text: PAGE_TITLES.SUB_NAV_DOCS,
    icon: null,
    routeName: SUB_NAV_ROUTES.DOCS,
  },
  {
    text: PAGE_TITLES.SUB_NAV_ANALYTICS,
    icon: null,
    routeName: SUB_NAV_ROUTES.ANALYTICS,
  },
  {
    text: PAGE_TITLES.CUSTOMER_SERVICES,
    icon: null,
    routeName: SUB_NAV_ROUTES.CUSTOMER_SERVICES,
  },
  {
    text: PAGE_TITLES.SUB_NAV_UNIVERSE,
    icon: null,
    routeName: null,
  },
  {
    text: PAGE_TITLES.SUB_NAV_QUANTUM,
    icon: null,
    routeName: null,
  },
  {
    text: PAGE_TITLES.SUB_NAV_SALESFORCE,
    icon: null,
    routeName: null,
  },
  {
    text: PAGE_TITLES.SUB_NAV_GATEKEEPER,
    icon: null,
    routeName: null,
  },
  {
    text: PAGE_TITLES.SUB_NAV_AGRESSO,
    icon: null,
    routeName: null,
  },
  {
    text: PAGE_TITLES.SUB_NAV_SUSTAINABILITY,
    icon: null,
    routeName: null,
  },
  {
    text: PAGE_TITLES.SUB_NAV_INNOVATION,
    icon: null,
    routeName: null,
  },
];

export const SEARCH_WIDGET_ENABLED_ROUTES = [ROUTES.DOCS_CATEGORY];

export const ALLOWED_DOCS_PARAMS = [
  WIDGET_CATEGORIES.PT_POLICY,
  WIDGET_CATEGORIES.IT_POLICY,
];

export const ALLOWED_ANALYTICS_PARAMS = [WIDGET_CATEGORIES.CRM];
