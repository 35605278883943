import React, { useState, useEffect } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Autocomplete } from "@mui/material";
import styled from "@emotion/styled";

import {
  resetDepot,
  handleVolumeSimulatorDepotChange,
  resetPageData,
} from "../../reducers/app";
import {
  volumeOverlayDateSelector,
  userAccessDepotListSelector,
  defaultOverlayDataSelector,
  simulatedVolumeOverlayDataSelector,
  overlayTimeBandSelector,
  depotCodeListSelector,
  simulationPercentageSelector,
} from "../../reducers/app/selector";
// import { firebaseUserSelector } from "../../reducers/user/selector";
import { VolumeOverlay } from "./VolumeOverlay";
import { SimulationSlider } from "./SimulationSlider/index.js";
import { DEFAULT_MAPBOUNDS } from "../../constants/map.js";
import { OUTER } from "../../constants/layers.js";
import "./Header.css";
import { setMapBounds } from "../../reducers/map/index.js";
import { setBoundariesForSimulationMap } from "../../reducers/depot/index.js";
import { AppActions } from "../../../../store";
import { setUpSimulationParcelList } from "../../utils/map/index.js";
import { ArrowIcon } from "../../assets/icons/ArrowIcon.jsx";

const DepotDropdown = styled(Autocomplete)(({ theme }) => ({
  width: "75%",
}));

export const Header = ({ map }) => {
  const [isMinimised, setIsMinimised] = useState(true);
  const { setLoading } = AppActions;

  const depotCodeList = useSelector(depotCodeListSelector);
  const overlayDate = useSelector(volumeOverlayDateSelector);
  const simulationPercentage = useSelector(simulationPercentageSelector);
  const volumeOverlayDate = useSelector(volumeOverlayDateSelector);
  const userAccessDepotList = useSelector(userAccessDepotListSelector);
  const defaultOverlayData = useSelector(defaultOverlayDataSelector);
  const simulatedOverlayData = useSelector(simulatedVolumeOverlayDataSelector);
  const overlayTimeBand = useSelector(overlayTimeBandSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (map) map.resize();
  }, [isMinimised, map, depotCodeList, overlayDate]);

  const depotDropDownList =
    userAccessDepotList?.length > 1
      ? userAccessDepotList.map((depot) => {
          const depotName = depot.depotName || "";
          const depotCode = depot.depotCode || "";
          return { depotName, depotCode, label: `${depotName} (${depotCode})` };
        })
      : [{ depotName: "", depotCode: "", label: "No Options" }];

  const currentDepotValue = depotDropDownList.find(
    ({ depotCode }) => depotCode === depotCodeList[0]
  );

  const OverlayTotalStopsBox = () => {
    const districtParcelTotals = setUpSimulationParcelList(
      OUTER,
      defaultOverlayData,
      simulatedOverlayData,
      overlayTimeBand,
      "total"
    );

    const totalComponentClassName = isMinimised
      ? "overlayTotalStopsMinimisedContainer"
      : "overlayTotalStopsContainer";

    return (
      <>
        {defaultOverlayData.length > 0 && (
          <div className={totalComponentClassName}>
            <div className="overlayTotalStopsSubContainer">
              <h5>Total Stops:</h5>
              <div className="overlayTotalStops">
                {districtParcelTotals.total}
              </div>
            </div>
            {simulatedOverlayData && (
              <div className="overlayTotalStopsSubContainer">
                <h5>Simulated Stops:</h5>
                <div className="overlayTotalStops">
                  {districtParcelTotals.simTotal}
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  const MinimisedView = () => {
    const formatteddepotCodes = depotCodeList.join(", ");
    const formattedDate = moment(overlayDate).format("DD/MM/YY");
    const formattedPercentage = `${simulationPercentage * 100}%`;

    return (
      <>
        {depotCodeList.length > 0 && (
          <div className="miniHeader">
            <div className="miniHeaderSegment">
              <h5>Depot(s): </h5>
              <div className="miniHeaderValues" title={formatteddepotCodes}>
                {formatteddepotCodes}
              </div>
            </div>
            {overlayDate && (
              <>
                <div className="miniHeaderSegment">
                  <h5>Date: </h5>
                  <div className="miniHeaderValues">
                    {overlayDate ? formattedDate : "None Selected"}
                  </div>
                </div>
                <div className="miniHeaderSegment">
                  <h5>Percentage: </h5>
                  <div className="miniHeaderValues">
                    {simulationPercentage > 0 ? formattedPercentage : "Default"}
                  </div>
                </div>
              </>
            )}
            <OverlayTotalStopsBox />
          </div>
        )}
      </>
    );
  };

  return (
    <div className="container">
      {!isMinimised ? (
        <div className="header">
          <div className="headerOptions">
            {/* Manual use of Volume Sim only works with 1 depot - for now */}
            <DepotDropdown
              key={`depot-dropdown-${depotCodeList?.[0]}`}
              className="headerDepotSearchBox"
              disablePortal
              blurOnSelect
              options={depotDropDownList.reverse()}
              value={currentDepotValue}
              onChange={async (event, newValue) => {
                // Pressing the clear button will set newValue to null
                if (!newValue) {
                  dispatch(resetDepot());
                  if (overlayDate) dispatch(resetPageData());
                  dispatch(
                    setBoundariesForSimulationMap({
                      sectorBoundaries: [],
                      districtBoundaries: [],
                    })
                  );
                  dispatch(setMapBounds(DEFAULT_MAPBOUNDS));
                  return;
                }
                const { depotName, depotCode } = newValue;
                dispatch(setLoading(true));
                // Ignore await message - await IS needed here otherwise loading screen will disappear instantly
                await dispatch(
                  handleVolumeSimulatorDepotChange({
                    depotNameList: [depotName],
                    depotCodeList: [depotCode],
                  })
                );
                dispatch(setLoading(false));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Choose Depot"
                  variant="outlined"
                />
              )}
            />

            <VolumeOverlay setLoading={setLoading} />
            <OverlayTotalStopsBox />
          </div>
          {depotCodeList?.length > 0 && volumeOverlayDate && (
            <SimulationSlider setLoading={setLoading} />
          )}
        </div>
      ) : (
        <MinimisedView />
      )}
      <button
        className="toggleButton"
        onClick={() => setIsMinimised(!isMinimised)}
      >
        {isMinimised ? "Show" : "Hide"} Options <ArrowIcon flip={isMinimised} />
      </button>
    </div>
  );
};
