import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import Slider from "@mui/material/Slider";
import { useDispatch, useSelector } from "react-redux";

import { UISelectors } from "../../../../../../store";
import { palette } from "../../../../styles/palette";
import {
  defaultOverlayDataSelector,
  overlayTimeBandSelector,
  volumeOverlayDateSelector,
} from "../../../../reducers/app/selector";
import "./TimeBandSlider.css";
import { setOverlayTimeBand } from "../../../../reducers/app";
import {
  checkFirstTimeBand,
  setUpTimeBands,
} from "../../../../utils/map/timeBands";
import {
  FULL_DAY,
  BUTTON_NUMBERS,
  FIRST_TIMEBAND,
  DEFAULT_TIMERANGE,
} from "../../../../constants/timeBands";
import { PAGE_SPLIT_8020_INDEX } from "../../../../../../constants/footer";

const StyledSlider = withStyles(() => ({
  root: {
    marginLeft: "11px",
  },
  thumb: {
    color: palette.dpdRed,
    border: palette.typography.black.solid,
    top: "55%",
    "$disabled &": {
      color: palette.grey,
    },
  },
  track: {
    color: palette.dpdRed,
    height: 6,
    "$disabled &": {
      color: palette.grey,
    },
  },
  rail: {
    color: palette.typography.white.solid,
    height: 6,
    "$disabled &": {
      color: palette.grey,
    },
  },
  mark: {
    color: palette.dpdRed,
    height: 6,
    "$disabled &": {
      color: palette.grey,
    },
  },
  markLabel: {
    color: palette.lightGrey,
    "$disabled &": {
      color: palette.grey,
    },
  },
  valueLabel: {
    marginTop: "2px",
    padding: "0 10px",
    top: "45px",
    "&:before": {
      bottom: "18px",
    },
  },
  disabled: {},
}))((props) => <Slider {...props} />);

const { getActiveScreenRatioButtonIndex } = UISelectors;

export const TimeBandSlider = () => {
  const dispatch = useDispatch();
  const [isFullRouteOn, setIsFullRouteOn] = useState(false);
  const [showLabelDisplay, setShowLabelDisplay] = useState(false);
  const volumeDate = useSelector(volumeOverlayDateSelector);
  const overlayTimeBand = useSelector(overlayTimeBandSelector);
  const defaultOverlayData = useSelector(defaultOverlayDataSelector);
  const activeScreenRatioButtonIndex = useSelector(
    getActiveScreenRatioButtonIndex
  );
  const [selectedBand, setSelectedBand] = useState(overlayTimeBand);
  const [selectedTimeRange, setSelectedTimeRange] = useState(DEFAULT_TIMERANGE);
  const [sliderValue, setSliderValue] = useState(16);

  useEffect(() => {
    if (showLabelDisplay) setTimeout(() => setShowLabelDisplay(false), 5000);
    // Fixes issue of the slider label will not disappear if you let go of the mouse while the cursor is off the component
  }, [showLabelDisplay]);

  useEffect(() => {
    setIsFullRouteOn(false);
    setSelectedBand(overlayTimeBand);
    setSliderValue(16);
    // Reset timeband on date change
    // eslint-disable-next-line
  }, [defaultOverlayData]);

  let firstBand = checkFirstTimeBand(
    FIRST_TIMEBAND,
    defaultOverlayData,
    volumeDate
  );

  const timeBands = setUpTimeBands(firstBand, selectedTimeRange);
  const maxValue = timeBands.length - 1;

  const isTimeBandEven = (timeBand) => {
    // Only here to stop the overflow of labels when volume only takes up 20% of the screen
    // TODO - Could refactor this at some point
    if (activeScreenRatioButtonIndex !== PAGE_SPLIT_8020_INDEX) return true;
    return parseInt(timeBand.slice(0, 2)) % 2 === 0;
  };

  const sliderValues = timeBands.map((timeBand, index) => ({
    value: index,
    label:
      (timeBand.endsWith("00") || firstBand === timeBand) &&
      isTimeBandEven(timeBand)
        ? timeBand
        : null,
    timeBand,
  }));

  const onSliderChange = (value) => {
    setShowLabelDisplay(true);
    setSliderValue(value);
    const newTimeBand = sliderValues.find(
      (sliderValue) => sliderValue.value === value
    ).timeBand;
    if (newTimeBand !== overlayTimeBand) {
      dispatch(setOverlayTimeBand(`${newTimeBand}:00`));
      setSelectedBand(`${newTimeBand}:00`);
    }
  };

  const onFullRouteToggle = () => {
    setShowLabelDisplay(false);
    setIsFullRouteOn(!isFullRouteOn);
    if (isFullRouteOn) {
      dispatch(setOverlayTimeBand(selectedBand));
    } else {
      dispatch(setOverlayTimeBand(null));
    }
  };

  const onTimeRangeSelection = (buttonNumber) => {
    if (buttonNumber < parseInt(selectedBand.substring(0, 2))) {
      const maxTimeBand =
        buttonNumber < 10 ? `0${buttonNumber}:00` : `${buttonNumber}:00`;
      const newSliderValue = sliderValues.find(
        (sliderValue) => sliderValue.timeBand === maxTimeBand
      ).value;
      setSliderValue(newSliderValue);
      dispatch(setOverlayTimeBand(`${maxTimeBand}:00`));
      setSelectedBand(`${maxTimeBand}:00`);
    }
    setSelectedTimeRange(parseInt(buttonNumber));
  };

  return (
    <div className="sliderContainer">
      <div className="slider">
        <StyledSlider
          key={`slider-${volumeDate}`} // Rerenders Slider on datePicker change so the Slider resets back to default value
          marks={sliderValues}
          steps={timeBands.length}
          max={maxValue}
          defaultValue={0}
          value={sliderValue}
          valueLabelDisplay={!showLabelDisplay ? "off" : "on"}
          valueLabelFormat={(value) => timeBands[value]}
          onMouseUp={() => setShowLabelDisplay(false)}
          onChange={(e, value) => onSliderChange(value)}
          disabled={isFullRouteOn}
        />
      </div>
      <div>
        <label className="sliderCheckboxContainer">
          <div>{FULL_DAY}</div>
          <input
            className="sliderCheckbox"
            type="checkbox"
            value="full"
            checked={isFullRouteOn ? "checked" : null}
            onChange={onFullRouteToggle}
          />
        </label>
      </div>
      <div className="sliderButtonContainer">
        {BUTTON_NUMBERS.map((button_number) => {
          const buttonNumber = parseInt(button_number);
          return (
            <button
              key={button_number}
              className={`sliderButton ${
                buttonNumber === selectedTimeRange ? "sliderButtonActive" : ""
              }`}
              onClick={() => onTimeRangeSelection(buttonNumber)}
              value={buttonNumber}
            >
              {buttonNumber}h
            </button>
          );
        })}
      </div>
    </div>
  );
};
