import { Avatar, Box, Grid, IconButton } from "@mui/material";
import styled from "@emotion/styled";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOffAltOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownOffAltOutlined";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDown from "@mui/icons-material/ThumbDown";
import ContentCopy from "@mui/icons-material/ContentCopy";
import VolumeUp from "@mui/icons-material/VolumeUp";
import ReactMarkdown from "react-markdown";

import chatAvatar from "../../assets/avatar.svg";

const UserMessageContainer = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "isUser",
})(({ isUser, theme }) => ({
  display: "flex",
  marginBottom: 32,
  padding: "0 20px",
  justifyContent: isUser ? "flex-end" : "flex-start",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    padding: 0,
  },
}));

const MessageBox = styled(Box)(({ theme }) => ({
  fontFamily: theme.fonts.plutoRegular,
  color: theme.colors.brandBlack,
  textAlign: "start",
  padding: "16px 24px 16px 30px",
  borderRadius: theme.radius.large,
  width: "90%",
  wordWrap: "break-word",
}));

const UserMessageBox = styled(MessageBox)(({ theme }) => ({
  backgroundColor: theme.colors.warmGrey80,
  color: theme.colors.brandBlack,
  borderRadius: theme.radius.large,
  borderTopRightRadius: theme.radius.small,
  marginRight: 16,
}));

const ChatMessageBox = styled(MessageBox)(({ theme }) => ({
  backgroundColor: theme.colors.brandWhite,
  borderTopLeftRadius: theme.radius.small,
}));

const ChatAvatar = styled(Avatar)({
  marginRight: 16,
  width: 25,
  height: 25,
});

const TextAvatar = styled(ChatAvatar)(({ theme }) => ({
  marginLeft: 0,
  backgroundColor: theme.colors.brandRed,
  fontSize: "13.5px",
}));

const ResponseFooter = styled(Grid)({
  paddingTop: 10,
  flexDirection: "row",
  justifyContent: "flex-end",
});

const MessageWrapper = styled(ReactMarkdown)(({ theme }) => ({
  "& a": {
    fontWeight: 700,
    color: theme.colors.blue,
  },
  "& code": {
    whiteSpace: "pre-wrap",
  },
}));

const ChatResponse = ({
  message,
  thumbUpClick,
  thumbDownClick,
  feedbackType,
  disableThumbs,
  isSystem,
}) => {
  const onCopyClick = () => {
    navigator.clipboard.writeText(message);
  };

  const onTextToSpeech = () => {
    const utt = new window.SpeechSynthesisUtterance(message);
    window.speechSynthesis.cancel();
    window.speechSynthesis.speak(utt);
  };

  return (
    <ChatMessageBox>
      <MessageWrapper
      // linkTarget={"_blank"}
      >
        {message}
      </MessageWrapper>

      {!isSystem && (
        <>
          {/* <Divider /> */}
          <ResponseFooter container>
            <IconButton onClick={thumbUpClick} disabled={disableThumbs}>
              {feedbackType === 1 ? <ThumbUpIcon /> : <ThumbUpOutlinedIcon />}
            </IconButton>
            <IconButton onClick={thumbDownClick} disabled={disableThumbs}>
              {feedbackType === 2 ? (
                <ThumbDown />
              ) : (
                <ThumbDownAltOutlinedIcon />
              )}
            </IconButton>
            <IconButton onClick={onCopyClick}>
              <ContentCopy />
            </IconButton>
            <IconButton onClick={onTextToSpeech}>
              <VolumeUp />
            </IconButton>
          </ResponseFooter>
        </>
      )}
    </ChatMessageBox>
  );
};

const Message = ({
  message,
  isUser,
  thumbDownClick,
  thumbUpClick,
  feedbackType,
  user,
  id,
  isSystem,
}) => {
  const { picture, firstName, lastName } = user;
  const avatarUserName = `${firstName[0]}${lastName[0]}`;
  return (
    <UserMessageContainer item isUser={isUser}>
      {isUser ? (
        <>
          <UserMessageBox message={message}>{message}</UserMessageBox>
          {picture ? (
            <ChatAvatar
              alt="User"
              src={picture}
              imgProps={{ referrerPolicy: "no-referrer" }}
            />
          ) : (
            <TextAvatar alt="UserAsText">{avatarUserName}</TextAvatar>
          )}
        </>
      ) : (
        <>
          <ChatAvatar alt="Chat" src={chatAvatar} />
          <ChatResponse
            message={message}
            thumbDownClick={thumbDownClick}
            thumbUpClick={thumbUpClick}
            feedbackType={feedbackType}
            disableThumbs={(!!feedbackType && feedbackType !== 0) || !id}
            isSystem={isSystem}
          />
        </>
      )}
    </UserMessageContainer>
  );
};

export default Message;
