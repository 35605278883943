import { createSlice } from "@reduxjs/toolkit";
import { PAGES } from "../../constants/pages";
import {
  handleVolumeSimulatorDepotChange,
  handleVolumeSimulatorDateChange,
  handleSimulation,
} from "./thunkActions";

const initialState = {
  // All
  pageToShowIndex: 0, // PAGES = { 0: "SIMULATION", 1: "POSTCODE ALLOCATION" }
  signInErrorMessage: "",
  showErrorModal: false,
  errorModalMessage: "",
  userAccessDepotList: [],
  // Simulation
  depotNameList: [],
  depotCodeList: [],
  volumeOverlayDate: null,
  simulationPercentage: 0,
  simulatedVolumeOverlayData: null,
  overlayTimeBand: null,
  defaultOverlayData: [],
  activeDatesForSimulationDatePicker: [],
  // Postcode Allocation
  allDepotList: [],
  depotsVisibleOnMapList: [],
  depotColourMap: [],
  fullMapParcelList: [],
  fullMapOverlayDate: null,
  activeDatesForAllocationDatePicker: [],
};

export const appSlice = createSlice({
  name: "vs_app",
  initialState,
  reducers: {
    resetDepot: (state) => {
      state.depotNameList = [];
      state.depotCodeList = [];
    },
    setPageToShowIndex: (state, action) => {
      if (Object.keys(PAGES).map(Number).includes(action.payload)) {
        state.pageToShowIndex = action.payload;
      } else {
        state.pageToShowIndex = 0;
      }
    },
    setSignInErrorMessage: (state, action) => {
      state.signInErrorMessage = action.payload;
    },
    setShowErrorModal: (state, action) => {
      state.showErrorModal = action.payload;
    },
    setErrorModalMessage: (state, action) => {
      state.errorModalMessage = action.payload;
    },
    setUserAccessDepotList: (state, action) => {
      state.userAccessDepotList = action.payload;
    },
    setVolumeOverlayDate: (state, action) => {
      state.volumeOverlayDate = action.payload;
    },
    setSimulationPercentage: (state, action) => {
      state.simulationPercentage = action.payload;
    },
    setSimulatedVolumeOverlayData: (state, action) => {
      state.simulatedVolumeOverlayData = action.payload;
    },
    setDefaultOverlayData: (state, action) => {
      state.defaultOverlayData = action.payload;
    },
    setOverlayTimeBand: (state, action) => {
      state.overlayTimeBand = action.payload;
    },
    setActiveDatesForSimulationDatePicker: (state, action) => {
      state.activeDatesForSimulationDatePicker = action.payload;
    },
    setAllDepotList: (state, action) => {
      state.allDepotList = action.payload;
      state.depotsVisibleOnMapList = action.payload;
    },
    setDepotsVisibleOnMapList: (state, action) => {
      state.depotsVisibleOnMapList = action.payload;
    },
    setDepotColourMap: (state, action) => {
      state.depotColourMap = action.payload;
    },
    setFullMapParcelList: (state, action) => {
      state.fullMapParcelList = action.payload;
    },
    setFullMapOverlayDate: (state, action) => {
      state.fullMapOverlayDate = action.payload;
    },
    setActiveDatesForAllocationDatePicker: (state, action) => {
      state.activeDatesForAllocationDatePicker = action.payload;
    },
    resetPageData: (state, action) => {
      state.volumeOverlayDate = null;
      state.simulationPercentage = 0;
      state.defaultOverlayData = [];
      state.overlayTimeBand = null;
      state.simulatedVolumeOverlayData = null;
    },
    resetAppState: (state, action) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleVolumeSimulatorDepotChange.fulfilled, (state, action) => {
        if (action?.payload) {
          const { activeOverlayDates, depotCodeList, depotNameList } =
            action.payload;
          state.depotCodeList = depotCodeList;
          state.depotNameList = depotNameList;
          state.activeDatesForSimulationDatePicker = activeOverlayDates;
          state.volumeOverlayDate = null;
          state.defaultOverlayData = [];
          state.simulationPercentage = 0;
          state.simulatedVolumeOverlayData = null;
        }
      })
      .addCase(handleVolumeSimulatorDepotChange.rejected, (state, action) => {
        state.showErrorModal = true;
        state.errorModalMessage = action.payload;
        state.depotNameList = [];
        state.depotCodeList = [];
      })
      .addCase(handleVolumeSimulatorDateChange.fulfilled, (state, action) => {
        if (action?.payload) {
          const { defaultOverlayData, overlayTimeBand, volumeOverlayDate } =
            action.payload;
          state.defaultOverlayData = defaultOverlayData;
          state.overlayTimeBand = overlayTimeBand;
          state.volumeOverlayDate = volumeOverlayDate;
        }
      })
      .addCase(handleVolumeSimulatorDateChange.rejected, (state, action) => {
        state.showErrorModal = true;
        state.errorModalMessage = action.payload;
        state.depotNameList = [];
        state.depotCodeList = [];
      })
      .addCase(handleSimulation.fulfilled, (state, action) => {
        const { simulatedVolumeOverlayData, simulationPercentage } =
          action.payload;
        state.simulatedVolumeOverlayData = simulatedVolumeOverlayData;
        state.simulationPercentage = simulationPercentage;
      })
      .addCase(handleSimulation.rejected, (state, action) => {
        state.showErrorModal = true;
        state.errorModalMessage = action.payload;
      });
  },
});

const { reducer, actions } = appSlice;
const {
  resetDepot,
  setPageToShowIndex,
  setUserAccessDepotList,
  setVolumeOverlayDate,
  setSimulationPercentage,
  setSimulatedVolumeOverlayData,
  setOverlayTimeBand,
  setAllDepotList,
  setDepotsVisibleOnMapList,
  setDepotColourMap,
  setActiveDatesForAllocationDatePicker,
  setActiveDatesForSimulationDatePicker,
  setDefaultOverlayData,
  setSignInErrorMessage,
  setErrorModalMessage,
  setShowErrorModal,
  setFullMapParcelList,
  setFullMapOverlayDate,
  resetPageData,
  resetAppState,
} = actions;
export {
  initialState,
  reducer,
  resetDepot,
  setPageToShowIndex,
  setUserAccessDepotList,
  setVolumeOverlayDate,
  setSimulationPercentage,
  setSimulatedVolumeOverlayData,
  setOverlayTimeBand,
  setAllDepotList,
  setDepotsVisibleOnMapList,
  setDepotColourMap,
  setActiveDatesForAllocationDatePicker,
  setActiveDatesForSimulationDatePicker,
  setDefaultOverlayData,
  setSignInErrorMessage,
  setErrorModalMessage,
  setShowErrorModal,
  setFullMapParcelList,
  setFullMapOverlayDate,
  resetPageData,
  resetAppState,
  handleVolumeSimulatorDepotChange,
  handleVolumeSimulatorDateChange,
  handleSimulation,
};
